import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import './Users.css';
import Swal from 'sweetalert2';

const Users = () => {
  const { auth, logout } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.insigniabyavianca.com/api/users/', {
          headers: {
            Authorization: `Bearer ${auth.access}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          const filteredUsers = data.filter(user => user.id !== 1);
          setUsers(filteredUsers);
        } else {
          console.log(data.message || 'Failed to fetch users');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUsers();
    const intervalId = setInterval(fetchUsers, 2000);
    return () => clearInterval(intervalId);
  }, [auth.access]);

  const handleEditClick = (userId, currentComment) => {
    setEditingUserId(userId);
    setComment(currentComment || '');
  };

  const handleSaveClick = async (userId) => {
    if (comment.trim() === '') {
      Swal.fire({
        title: 'Error!',
        text: 'El campo comentario no puede estar vacio.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    try {
      const response = await fetch('https://api.insigniabyavianca.com/api/create-comment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.access}`
        },
        body: JSON.stringify({ comment, user_id: userId })
      });
      if (response.ok) {
        Swal.fire({
          title: 'Vuelo de papel!',
          text: 'Comentario guardado con éxito.',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
        setEditingUserId(null);
        setComment('');
      } else {
        const data = await response.json();
        alert(data.message || 'Error al guardar el comentario');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error al guardar el comentario');
    }
  };

  return (
    <>
      <div className='users-container-header'>
        <div className='logo-users'>
          <img className='logo-img-users' src="/logo-avianca.png" alt="logo avianca"/>
        </div>
        <div className='button-container-user' onClick={logout}>
            <div className='button-user'>Salir</div>
        </div>
      </div>
      <div className="users-container">
        <h3>Lista de usuarios</h3>
        <div className="users-table">
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Correo Electrónico</th>
                <th>País de residencia</th>
                <th>Número telefónico</th>
                <th>Comentario</th>
                <th>Fecha de Creación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td data-label="Nombre">{user.name}</td>
                  <td data-label="Apellido">{user.last_name}</td>
                  <td data-label="Correo Electrónico">{user.email}</td>
                  <td data-label="País">{user.country_of_origin}</td>
                  <td data-label="Número telefónico">{user.phone}</td>
                  <td data-label="Comentario" style={{maxWidth: 150, overflowWrap: 'break-word'}}>
                    {editingUserId === user.id ? (
                      <textarea
                        type="text"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        style={{width: 150, border: 'solid 1px', height: 150, overflowWrap: 'break-word'}}
                      />
                    ) : (
                      <span >{user.user_comments[0]?.comment}</span>
                    )}
                  </td>
                  <td data-label="Fecha de Creación">{new Date(user.created).toLocaleDateString()}</td>
                  <td data-label="Acciones">
                    {editingUserId === user.id ? (
                      <div className='button-container' onClick={() => handleSaveClick(user.id)}>
                        <div className='button' style={{fontSize:16, color: 'green', backgroundColor:'white'}}>Guardar</div>
                      </div>
                    ) : (
                      <div className='button-container' onClick={() => handleEditClick(user.id, user.user_comments[0]?.comment)}>
                        <div className='button' style={{fontSize:16}}>Editar</div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Users;
