import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Verificación de campo de correo electrónico vacío
    if (!email) {
      Swal.fire({
        title: 'Error!',
        text: 'El campo correo electrónico es obligatorio.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    // Verificación de formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        title: 'Error!',
        text: 'El correo electrónico no es válido.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    try {
      const response = await fetch('https://api.insigniabyavianca.com/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (response.ok) {
        login({ name: data.name, access: data.access });
        navigate('/users'); // Redirección después de iniciar sesión
      } else {
        Swal.fire({
          title: 'Vuelo Papel!',
          text: data.non_field_errors || 'Login failed',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='container-main'>
      <img  className='arrow2' src="arrow-1.png" alt="" />
        <img  className='arrow1' src="arrow-2.png" alt="" />
        <img  className='nube1' src="nube.png" alt="" />
        <img  className='nube2' src="nube.png" alt="" />
        <img  className='nube3' src="nube.png" alt="" />
        <img  className='nube4' src="nube.png" alt="" />
        <img  className='nube5' src="nube.png" alt="" />
      <div className='container'>
        <div className='logo'>
          <img className='logo-img' src="/logo-avianca.png" alt="logo avianca"/>
        </div>
        <form onSubmit={handleLogin}>
          <div className='container-form text-form'>
            <div className='form-item'>
              <label>Correo electrónico:</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='container-logoa'>
              <img className='logoa-img' src="logo-a.png" alt="logo a" />
            </div>
          </div>
          <div className='button-container' onClick={handleLogin}>
            <div className='button'>Ingresar</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
