import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './Register.css';

const Register = () => {
	const [formData, setFormData] = useState({
		email: '',
		name: '',
		last_name: '',
		birth_date: '',
		country_of_origin: '',
		language: 'Español',
		phone: '',
		term_1: true,  // Booleano
		term_2: true,  // Booleano
		term_3: false,  // Nuevo checkbox 3
		term_4: false,  // Nuevo checkbox 4
		term_5: false,  // Nuevo checkbox 5
	});

	const languages = ['Alemán', 'Español', 'Francés', 'Inglés'];

	useEffect(() => {
	}, [formData]);  

	const handleChange = (e) => {
		const { name, type, checked, value } = e.target;

		if (type === 'radio') {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value === 'true', 
			}));
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: type === 'checkbox' ? checked : value,
			}));
		}
	};

	const validateFields = () => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!formData.name) return 'El campo nombre es obligatorio.';
		if (!formData.last_name) return 'El campo apellidos es obligatorio.';
		if (!formData.email) return 'El campo correo electrónico es obligatorio.';
		if (!emailRegex.test(formData.email)) return 'El correo electrónico no es válido.';
		if (!formData.birth_date) return 'El campo fecha de nacimiento es obligatorio.';
		if (!formData.country_of_origin) return 'El campo país de origen es obligatorio.';
		if (!formData.phone) return 'El campo número telefónico es obligatorio.';
		if (!formData.term_3) return 'Debe aceptar el tratamiento de datos personales conforme a la política de Privacidad .';
		if (!formData.term_4) return 'Debe aceptar los términos y condiciones.';

		return null; 
	};

	const handleRegister = async (e) => {
		e.preventDefault();

		const validationError = validateFields();
		if (validationError) {
			Swal.fire({
				title: 'Error!',
				text: validationError,
				icon: 'error',
				confirmButtonText: 'Aceptar',
		 
			});
			return;
		}

		try {
			const response = await fetch('https://api.insigniabyavianca.com/api/user-register/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				Swal.fire({
					title: '¡Registro exitoso!',
					text: 'Te has registrado correctamente.',
					icon: 'success',
					confirmButtonText: 'Aceptar',
				});
				setFormData({
					email: '',
					name: '',
					last_name: '',
					birth_date: '',
					country_of_origin: '',
					language: 'Español',
					phone: '',
					term_1: false,
					term_2: false,
					term_3: false, 
					term_4: false, 
				});
			} else {
				const data = await response.json();
				const errorMessage = data.email
					? 'El email ya se encuentra registrado.'
					: 'Revisa los datos ingresados.';
				Swal.fire({
					title: 'Error!',
					text: errorMessage,
					icon: 'error',
					confirmButtonText: 'Aceptar',
				});
			}
		} catch (error) {
			Swal.fire({
				title: 'Error!',
				text: 'Ocurrió un error al registrar el usuario.',
				icon: 'error',
				confirmButtonText: 'Aceptar',
			});
			console.error('Error:', error);
		}
	};

	const formStyles = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	};

	const titleStyle = {
		color: 'white',
		fontSize: '14px',
		marginTop: '15px',
		marginBottom: '15px',
		textAlign: 'center',
	}

	return (
		<div className="container-main">
			<div className="aviancaIco"/>
			<div>
				<img src='https://interactiva.net.co/imagenes/avianca/LogoInsignia.png' alt='' className='imgIco' />
			</div>
			<h1 style={titleStyle}>regístrate y descubre más de INSIGNIA by avianca</h1>
			<form style={formStyles} onSubmit={handleRegister}>
				<div className="container-form text-form">
					<div className="form-group">
						<div className="form-row">
							<input
								type="text"
								name="name"
								placeholder="Nombre"
								value={formData.name}
								onChange={handleChange}
								className="input-field"
							/>
						</div>

						<div className="form-row">
							<input
								type="text"
								name="last_name"
								placeholder="Apellidos"
								value={formData.last_name}
								onChange={handleChange}
								className="input-field"
							/>
						</div>

						<div className="form-row">
							<input
								type="email"
								name="email"
								placeholder="Correo electrónico"
								value={formData.email}
								onChange={handleChange}
								className="input-field"
							/>
						</div>

						<div className="form-row">
							<div className="input-container">
								<input
									type="date"
									name="birth_date"
									value={formData.birth_date}
									onChange={handleChange}
									className="input-field"
								/>
								{/* Mostrar el "placeholder" cuando el campo está vacío */}
								{!formData.birth_date && (
									<span className="date-placeholder">Fecha de nacimiento</span>
								)}
							</div>
						</div>

						<div className="form-row">
							<input
								type="text"
								name="country_of_origin"
								placeholder="País de origen"
								value={formData.country_of_origin}
								onChange={handleChange}
								className="input-field"
							/>
						</div>

						<div className="form-row input-select-wrapper">
							<select
								name="language"
								value={formData.language}
								onChange={handleChange}
								className="input-select"
							>
								{languages.map((lang, idx) => (
									<option key={idx} value={lang}>
										{lang}
									</option>
								))}
							</select>
						</div>


						<div className="form-row">
							<input
								type="text"
								name="phone"
								placeholder="Número de teléfono"
								value={formData.phone}
								onChange={handleChange}
								className="input-field"
							/>
						</div>

						<div className="form-row">
							<p>¿Has viajado alguna vez en business class?</p>
							<div className="cointainerChecks">
								<div className='cointainerCheck'>
									<label>
										Si
									</label>
									<input
										type="radio"
										className='radioButton'
										name="term_1"
										value="true"
										checked={formData.term_1 === true} 
										onChange={handleChange}
									/>

								</div>
								<div className='cointainerCheck'>
									<label>
										No
									</label>
									<input
										type="radio"
										className='radioButton'
										name="term_1"
										value="false" 
										checked={formData.term_1 === false} 
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						<div className="form-row">
							<p>¿Tienes algún viaje internacional programado para los próximos 6 meses?</p>

							<div className='cointainerChecks'>
								<div className='cointainerCheck'>
									<label>
										Sí
									</label>
									<input
										type="radio"
										className='radioButton'
										name="term_2"
										value="true" // Se guarda "true" como valor
										checked={formData.term_2 === true} // Comprobamos si es true
										onChange={handleChange}
									/>
								</div>
								
								<div className="cointainerCheck">
									<label>
										No
									</label>
									<input
										type="radio"
										className='radioButton'
										name="term_2"
										value="false" // Se guarda "false" como valor
										checked={formData.term_2 === false} // Comprobamos si es false
										onChange={handleChange}
									/>
								</div>
							</div>

						</div>

						{/* Nuevos checkboxes agregados */}
						<div className="form-row container-center">
							<input
								type="checkbox"
								className='checkbox'
								name="term_3"
								checked={formData.term_3}
								onChange={handleChange}
							/>
							<a href='https://www.avianca.com/es/informacion-legal/politica-privacidad/' target='_blank' rel="noreferrer" className='classLink underline-none'>
								<p>Autorizo el tratamiento de mis datos personales conforme a la <span className='underline'>política de Privacidad de Avianca.</span></p>
							</a>
						</div>


						<div className="form-row container-center">
							<input
								type="checkbox"
								className='checkbox'
								name="term_4"
								checked={formData.term_4}
								onChange={handleChange}
							/>
							<a href='https://tturl.co/lPRqrb' target='_blank' rel="noreferrer" className='classLink underline-none'>
								<p>Acepto los <span className='underline'>términos y condiciones</span> de la actividad "Dinámica Vassar".</p>
							</a>
						</div>

					</div>
				</div>
				<button type="submit" className="button">
					Regístrate
				</button>
			</form>
		</div>
	);
};

export default Register;
